import Web3 from 'web3'
import { isNumber } from 'lodash-es'
import { IMultiClaimContract } from './multiclaim-contract-interface'
import { SolidityMultiClaimContract } from './multiclaim-contract-solidity'
import { walletStore } from '@/stores/wallet-store'
import { IMultiSendContract } from './multisend-contract-interface'
import { SolidityMultiSendContract } from './multisend-contract-solidity'
import { CLUSTER_SLUGS, ENV as SOL_CHAINID } from '@solana/spl-token-registry'
import { MultiSendSolana } from './multisend-solana'
import { ProgramError, Provider, Wallet } from '@project-serum/anchor'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { clusterApiUrl } from '@solana/web3.js'
import { ConfirmOptions, Connection, SendTransactionError } from '@solana/web3.js'
import { MyAnchorWallet } from '@/stores/wallet-store'

const MULTI_CLAIM_CONTRACT_BSC_TEST = '0x97a5300a3F9EDac52BC0751697a5476701758c07'
const MULTI_CLAIM_CONTRACT_BSC_MAIN = '0x97a5300a3F9EDac52BC0751697a5476701758c07'
const MULTI_CLAIM_CONTRACT_MATIC_TEST = '0xf873CcE36281eb7F7AC4B05da9e606B46C0AD888'
const MULTI_CLAIM_CONTRACT_MATIC_MAIN = '0xd7A9986815E26EdB9658d0463d2C09118C0BcDCE'
const MULTI_CLAIM_CONTRACT_AVAX_TEST = '0x7960A25Cf7cB0e24DD07df1d8647A1D05378D784'
const MULTI_CLAIM_CONTRACT_AVAX_MAIN = '0x9dc0b1d359793D9B63a3f4a9a9fCbeB53a61899E'
const MULTI_CLAIM_CONTRACT_ETH_TEST = '0x2BEC39320F8A250716FeeA677dF9BF2D523Ea449'
const MULTI_CLAIM_CONTRACT_ETH_MAIN = '0x07f8e66564F60Ce0a6aA13F0fc39B76403414b03'

const MULTI_SEND_CONTRACT_BSC_TEST = '0xee3afaeD8D4B40d84e3C3C36f4337B07B4213517'
const MULTI_SEND_CONTRACT_BSC_MAIN = '0xBe1D412c4F86baAed9edC995A003bB7A256C8321'
const MULTI_SEND_CONTRACT_MATIC_TEST = '0xbEBc77E59971edB428Dc25286cCe6413C37a22CB'
const MULTI_SEND_CONTRACT_MATIC_MAIN = '0xB1B3dd5556eB0Fa400803E7dC775633a99d62aa9'
const MULTI_SEND_CONTRACT_AVAX_TEST = '0x9dc0b1d359793D9B63a3f4a9a9fCbeB53a61899E'
const MULTI_SEND_CONTRACT_AVAX_MAIN = '0xbEBc77E59971edB428Dc25286cCe6413C37a22CB'
const MULTI_SEND_CONTRACT_ETH_MAIN = '0xbEBc77E59971edB428Dc25286cCe6413C37a22CB'
const MULTI_SEND_CONTRACT_ETH_TEST = '0xbEBc77E59971edB428Dc25286cCe6413C37a22CB'

const getChainConfig = (chainId: any) => {
  chainId = isNumber(+chainId) ? +chainId : chainId
  let rpc = ''
  let name = ''
  switch (chainId) {
    case 1:
      name = 'Ethereum Mainnet'
      rpc = 'https://speedy-nodes-nyc.moralis.io/6fa069a27f1f69de0c130e79/eth/mainnet'
      break
    case 3:
      name = 'Ethereum Robsten'
      rpc = 'https://rinkey.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
      break
    case 56:
      name = 'BSC MainNET'
      rpc = 'https://bsc-dataseed.binance.org'
      break
    case 97:
      name = 'BSC TestNET'
      rpc = 'https://data-seed-prebsc-1-s1.binance.org:8545/'
      break
    case 137:
      name = 'Matic MainNET'
      rpc = 'https://rpc-mainnet.maticvigil.com/'
      break
    case 80001:
      name = 'Matic TestNET'
      rpc = 'https://rpc-mumbai.maticvigil.com/'
      break
    case 43114:
      name = 'Avax MainNET'
      rpc = 'https://api.avax.network/ext/bc/C/rpc'
      break
    case 43113:
      name = 'Avax MainNET'
      rpc = '	https://api.avax-test.network/ext/bc/C/rpc'
      break
    case 103:
      name = 'Solana DevNET'
      rpc = 'https://api.devnet.solana.com'
      break
    case 102:
      name = 'Solana TestNET'
      rpc = 'https://api.testnet.solana.com'
      break
    case 101:
      name = 'Solana MainNET'
      rpc = 'https://solana-api.projectserum.com'
      break
  }
  return { rpc, name }
}

const getWeb3 = (chainId: any) => {
  chainId = isNumber(+chainId) ? +chainId : chainId
  const { rpc, name } = getChainConfig(chainId)

  if (walletStore.walletName === 'WalletConnect') return walletStore.web3
  if (rpc) return new Web3(new Web3.providers.HttpProvider(rpc))
  else return null
}

const getSolanaConfig = chainId => {
  if (walletStore.connectedSolProvider) return walletStore.connectedSolProvider
  const anchorWallet = new MyAnchorWallet(undefined)
  const opts: ConfirmOptions = {
    preflightCommitment: 'recent',
    commitment: 'recent'
  }
  let connection: Connection

  switch (chainId) {
    case SOL_CHAINID.MainnetBeta:
      connection = new Connection('https://solana-api.projectserum.com', opts.preflightCommitment)
      break
    case SOL_CHAINID.Testnet:
      connection = new Connection(clusterApiUrl(WalletAdapterNetwork.Testnet), opts.preflightCommitment)
      break
    case SOL_CHAINID.Devnet:
    default:
      connection = new Connection(clusterApiUrl(WalletAdapterNetwork.Devnet), opts.preflightCommitment)
      break
  }
  return new Provider(connection, anchorWallet, opts)
}

const cachedContracts: { [id: string]: IMultiClaimContract } = {}
function multiclaimContractFactory(model: any): IMultiClaimContract | undefined {
  const { chainId, chain } = model
  let contractAddress = model.contractAddress
  if (!contractAddress) {
    if (+chainId === 56) {
      contractAddress = MULTI_CLAIM_CONTRACT_BSC_MAIN
    } else if (+chainId == 97) {
      contractAddress = MULTI_CLAIM_CONTRACT_BSC_TEST
    } else if (+chainId == 80001) {
      contractAddress = MULTI_CLAIM_CONTRACT_MATIC_TEST
    } else if (+chainId == 137) {
      contractAddress = MULTI_CLAIM_CONTRACT_MATIC_MAIN
    } else if (+chainId == 43114) {
      contractAddress = MULTI_CLAIM_CONTRACT_AVAX_MAIN
    } else if (+chainId == 43113) {
      contractAddress = MULTI_CLAIM_CONTRACT_AVAX_TEST
    } else if (+chainId == 1) {
      contractAddress = MULTI_CLAIM_CONTRACT_ETH_MAIN
    } else if (+chainId == 3) {
      contractAddress = MULTI_CLAIM_CONTRACT_ETH_TEST
    }
  }
  let result: any = undefined
  if (contractAddress) {
    result = cachedContracts[contractAddress]
    if (!result) {
      result = new SolidityMultiClaimContract(contractAddress, getWeb3(chainId) || walletStore.web3!)
      cachedContracts[contractAddress] = result
    }
  }

  return result
}

function multiSendContractFactory(model: any): any {
  const { chainId } = model
  if (+chainId == 101 || +chainId == 102 || +chainId == 103) {
    const result = new MultiSendSolana(getSolanaConfig(chainId))
    return result
  }
  let result: any = undefined
  let contractAddress = model.contractAddress
  if (!contractAddress) {
    if (+chainId === 56) {
      contractAddress = MULTI_SEND_CONTRACT_BSC_MAIN
    } else if (+chainId == 97) {
      contractAddress = MULTI_SEND_CONTRACT_BSC_TEST
    } else if (+chainId == 80001) {
      contractAddress = MULTI_SEND_CONTRACT_MATIC_TEST
    } else if (+chainId == 137) {
      contractAddress = MULTI_SEND_CONTRACT_MATIC_MAIN
    } else if (+chainId == 43114) {
      contractAddress = MULTI_SEND_CONTRACT_AVAX_MAIN
    } else if (+chainId == 43113) {
      contractAddress = MULTI_SEND_CONTRACT_AVAX_TEST
    } else if (+chainId == 1) {
      contractAddress = MULTI_SEND_CONTRACT_ETH_MAIN
    } else if (+chainId == 3) {
      contractAddress = MULTI_SEND_CONTRACT_ETH_TEST
    }
  }
  if (contractAddress) {
    result = cachedContracts[contractAddress]
    if (!result) {
      if (+chainId == 101 || +chainId == 102 || +chainId == 103) {
        result = new MultiSendSolana(getSolanaConfig(chainId))
      } else {
        result = new SolidityMultiSendContract(contractAddress, getWeb3(chainId)!)
        result.injectProvider()
      }
      cachedContracts[contractAddress] = result
    } else {
      result.injectProvider()
    }
  }

  return result
}

function etherBatchRequest(web3: Web3, methods: any[]) {
  if (!methods.length) return []
  const batch = new web3.BatchRequest()
  const tasks = Promise.all(
    methods?.map(
      method =>
        new Promise((resolve, reject) => {
          batch.add(
            method.call.request({}, function(error, result) {
              if (error) {
                console.error('Errror=', method, error)
                reject(error)
              } else {
                resolve(result)
              }
            })
          )
        })
    )
  )
  batch.execute()
  return tasks
}

export const blockchainHandler = {
  getChainConfig,
  getWeb3,
  ETHER_ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
  etherBatchRequest,
  multiclaimContractFactory,
  multiSendContractFactory
}
export type ChainIdType = '56' | '97'
