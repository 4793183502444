import * as anchor from '@project-serum/anchor'
import { Program, ProgramError, Provider } from '@project-serum/anchor'
import { TOKEN_PROGRAM_ID, Token, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { bnHelper } from '@/helpers/bignumber-helper'
import { SlpTokenProgram } from './slp-token-contract'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

const { web3, BN } = anchor
const { PublicKey, SystemProgram, Keypair, Transaction, LAMPORTS_PER_SOL, sendAndConfirmTransaction } = web3

export class MultiSendSolana {
  provider: Provider
  ata: any
  constructor(public providerConfig: Provider) {
    this.provider = providerConfig
  }

  async getSOLInfo(account: any) {
    const publicKey = new PublicKey(account)
    const balance = await this.provider.connection.getBalance(publicKey)
    return {
      decimals: 9,
      name: 'SOL',
      symbol: 'SOL',
      balance: bnHelper.fromDecimals(balance, 9)
    }
  }

  async getAssociatedTokenAddress(account: string, tokenAddress: string) {
    const publicKey = new PublicKey(account)
    return await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      new PublicKey(tokenAddress),
      publicKey,
      true
    )
  }

  async getAssociatedTokenAddressList(accounts: string[], tokenAddress: string) {
    return await Promise.all(
      accounts.map(account =>
        Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          new PublicKey(tokenAddress),
          new PublicKey(account),
          true
        )
      )
    )
  }

  async getTokenInfoByAddress(tokenAddress, account) {
    const token = new SlpTokenProgram(tokenAddress, this.provider)
    const balance = await token.getTokenAmount(account)
    const decimals = await token.decimals()

    return {
      name: tokenAddress,
      symbol: 'SPL',
      decimals: decimals,
      balance: balance,
      tokenAddress: tokenAddress
    }
  }

  async createAssociatedTokenAccountInstruction(walletAccount: string, accounts: string[], token: any) {
    const ataList = await this.getAssociatedTokenAddressList(accounts, token.tokenAddress)
    const walletAccountPublicKey = new PublicKey(walletAccount)
    const ataAccountInfos = await this.provider.connection.getMultipleAccountsInfo(ataList)
    const infos = accounts.map((account, index) => {
      return { account: new PublicKey(account), ata: ataList[index], hasAtaAccount: false }
    })
    infos.forEach((x, index) => {
      x.hasAtaAccount = !!ataAccountInfos[index]
    })
    const createAtas = infos.filter(x => !x.hasAtaAccount)
    if (createAtas.length === 0) return
    const transaction = new web3.Transaction()
    createAtas.forEach(item =>
      transaction.add(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          new PublicKey(token.tokenAddress),
          item.ata,
          item.account,
          walletAccountPublicKey
        )
      )
    )

    const transactionHash = await this.provider.send(transaction, [])
    return transactionHash
  }

  async bulkSend(addresses, values, account, token: any, callback: any) {
    const transaction = new web3.Transaction()
    const publicKey = new PublicKey(account)
    const ataList = await this.getAssociatedTokenAddressList(addresses, token.tokenAddress)
    const fromAssociatedAddress = await this.getAssociatedTokenAddress(account, token.tokenAddress)
    addresses.forEach((address, index) => {
      const amount = bnHelper.toDecimalString(values[index], token.decimals)
      transaction.add(
        Token.createTransferCheckedInstruction(
          TOKEN_PROGRAM_ID,
          fromAssociatedAddress,
          new PublicKey(token.tokenAddress),
          ataList[index],
          publicKey,
          [],
          +amount,
          token.decimals
        )
      )
    })

    try {
      const transactionHash = await this.provider.send(transaction, [], {})
      setTimeout(() => {
        callback({ completed: true })
      }, 0)
      return transactionHash
    } catch (error) {
      snackController.error(error.message || error.msg)
      callback({ completed: false })
    }
  }

  async bulkSendSol(addresses, values, account, token, callback) {
    try {
      const transaction = new web3.Transaction()
      addresses.forEach((address, index) =>
        transaction.add(
          web3.SystemProgram.transfer({
            fromPubkey: new PublicKey(account),
            toPubkey: new PublicKey(address),
            lamports: +bnHelper.toDecimalString(values[index], token.decimals)
          })
        )
      )
      const transactionHash = await this.provider.send(transaction, [], {})
      setTimeout(() => {
        callback({ completed: true })
      }, 0)
      return transactionHash
    } catch (error) {
      snackController.error(error.message || error.msg)
      setTimeout(() => {
        callback({ completed: false })
      }, 0)
    }
  }
}
