import { bnHelper } from '@/helpers/bignumber-helper'
import { ProgramError, Provider } from '@project-serum/anchor'
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { PublicKey, Keypair, Transaction } from '@solana/web3.js'

export class SlpTokenProgram {
  mint: PublicKey
  token: Token
  _decimals: number | null = null

  constructor(mint: string | PublicKey, public provider: Provider) {
    if (typeof mint === 'string') this.mint = new PublicKey(mint)
    else this.mint = mint
    this.token = new Token(provider.connection, this.mint, TOKEN_PROGRAM_ID, new Keypair())
  }

  injectProvider(provider: Provider) {
    this.provider = provider
    this.token = new Token(provider.connection, this.mint, TOKEN_PROGRAM_ID, new Keypair())
    this.token.getOrCreateAssociatedAccountInfo
  }

  async decimals() {
    const result = this._decimals || (await this.token.getMintInfo()).decimals
    this._decimals = result
    return result
  }

  // async isApproved(account, spenderAddress) {
  //   const approvedAmount = bnHelper.fromDecimals(
  //     await this.contract.methods.allowance(account, spenderAddress).call(),
  //     await this.decimals()
  //   )
  //   return bnHelper.gt(approvedAmount, FixedNumber.from(`999999`))
  // }

  // async approve(account, spenderAddress) {
  //   const amountWithDecimals = bnHelper.toDecimalString(`${2 ** 64 - 1}`, await this.decimals())
  //   return await sendRequest(this.contract.methods.approve(spenderAddress, amountWithDecimals), account)
  // }

  async getTokenAmount(account) {
    const ata = await this.getAssociatedTokenAddress(new PublicKey(account.toString()))
    const info = await this.token.getAccountInfo(ata)
    return bnHelper.fromSolDecimals(info.amount, await this.decimals())
  }

  async createAssociatedTokenAddress(owner) {
    owner = new PublicKey(owner.toString())
    const tx = new Transaction()
    tx.add(await this.getCreateAssociatedTokenAddressInstruction(owner))
    await this.provider.send(tx, [], {})
  }

  async getCreateAssociatedTokenAddressInstruction(owner) {
    owner = new PublicKey(owner.toString())
    const ata = await this.getAssociatedTokenAddress(owner)
    return Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      this.mint,
      ata,
      owner,
      owner
    )
  }

  getAssociatedTokenAddress(owner: PublicKey) {
    return Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, this.mint, owner, true)
  }

  async hasAssociatedTokenAddress(owner) {
    try {
      const ata = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        this.mint,
        new PublicKey(owner.toString()),
        true
      )
      await this.token.getAccountInfo(ata)
      return true
    } catch (error) {
      return false
    }
  }
}
