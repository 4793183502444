/* eslint-disable @typescript-eslint/no-var-requires */
class PriceHelper {
  /**
   * Greater than
   */
  async ethPerBnbRatio() {
    const { ChainId, Token, Fetcher, Route, Trade, TokenAmount, TradeType } = require('@overage69/pancake-sdk-v2')
    const { getDefaultProvider } = require('@ethersproject/providers')
    const provider = getDefaultProvider('https://bsc-dataseed1.binance.org')
    const Web3 = require('web3')
    const web3 = new Web3('https://bsc-dataseed1.binance.org')
    const WBNB = new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18)
    const getBep20TokenPriceBNB = async token => {
      const pair = await Fetcher.fetchPairData(token, WBNB, provider)
      const route = new Route([pair], token)
      const amount = new TokenAmount(token, web3.utils.toBN(Math.pow(10, token.decimals)))
      const trade = new Trade(route, amount, TradeType.EXACT_INPUT)
      return trade.executionPrice.toSignificant(18)
    }
    const ETH = new Token(ChainId.MAINNET, '0x2170ed0880ac9a755fd29b2688956bd959f933f8', 18)
    const res = await getBep20TokenPriceBNB(ETH)
    return res
  }
}

export default new PriceHelper()
