/* eslint-disable no-async-promise-executor */
import { bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { isNumber, map, toNumber, uniq, union, flattenDeep } from 'lodash-es'
import Web3 from 'web3'
import { blockchainHandler } from '.'
import { ClaimParam, IMultiClaimContract, VestingScheduler } from './multiclaim-contract-interface'
import priceHelper from '@/helpers/priceHelper.js'
import moment from 'moment'
import { ADDRESS_0 } from '@/constants'
const MAX_LENGTH_PARAM = 1000
export const DEFAULT_TOKEN_SYMBOL = 'BNB'

async function sendRequest(fx, from, value = '0') {
  return await new Promise((resolve, reject) => {
    fx.send({ from, value })
      .on('receipt', () => resolve(true))
      .on('error', error => reject(error))
  })
}

async function sendRequestWithCallBack(fx, from, callback = null as any) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('transactionHash', hash => {
        if (callback) resolve(hash)
      })
      .on('receipt', () => {
        console.log('reciept')
        if (callback) {
          callback({ completed: true })
        }
        resolve(true)
      })
      .on('error', error => {
        if (callback) {
          callback({ completed: false })
        }
        reject(error)
      })
  })
}

export class SolidityMultiClaimContract implements IMultiClaimContract {
  contract: any
  erc20Decimals = 18

  _txFee = FixedNumber.from('0')
  _isVIP = FixedNumber.from('0')
  _loadTask?: Promise<any>
  _loaded = false
  bnbInfo: any
  _cachedPool: { [id: string]: any } = {}

  constructor(public address: string, public web3: Web3) {
    this.contract = new web3.eth.Contract(require('../abis/multiclaim-contract.json'), this.address)
  }

  injectProvider() {
    const web3 = walletStore.web3!
    this.web3 = web3
    this.contract = new web3.eth.Contract(require('../abis/multiclaim-contract.json'), this.address)
  }

  init(): Promise<any> {
    if (this._loaded) {
      return Promise.resolve()
    }
    if (this._loadTask) {
      return this._loadTask
    }

    this._loadTask = new Promise(async (resolve, reject) => {
      try {
        const methods = this.contract.methods
        const [txFee, isVIP] = (await blockchainHandler.etherBatchRequest(this.web3, [
          methods.txFee(),
          methods.isVIP(walletStore.account)
        ])) as any

        this._txFee = bnHelper.fromDecimals(txFee)
        this._isVIP = isVIP
        this._loaded = true
        resolve(null)
      } catch (error) {
        reject(error)
      }
    })
    return this._loadTask
  }

  async approvedContract(account: string, token) {
    try {
      const tokenContract = new this.web3.eth.Contract(require('@/helpers/erc20.abi.json'), token.tokenAddress)
      const allowance = await tokenContract.methods.allowance(account, this.contract._address).call()
      return !!+this.web3.utils.fromWei(allowance)
    } catch (e) {
      console.error(e)
      // alert.error(e.message)
      return false
    }
  }

  async approveContract(account: string, token) {
    const tokenConract = new this.web3.eth.Contract(require('@/helpers/erc20.abi.json'), token.tokenAddress)
    const f = tokenConract.methods.approve(this.contract._address, this.web3.utils.toWei(`${2 ** 64 - 1}`))
    try {
      await sendRequest(f, account)
      // alert.success('approve successed!')
    } catch (e) {
      // alert.error('approve failed')
      // alert.error(e.message)
    }
  }

  async getBnbInfo(account: string) {
    try {
      const chainId = walletStore.chainId
      const bnbBalance = await this.web3.eth.getBalance(account)
      if (+chainId === 56 || +chainId == 97) {
        return {
          decimals: 18,
          name: 'BNB',
          symbol: DEFAULT_TOKEN_SYMBOL,
          balance: bnHelper.fromDecimals(bnbBalance)
        }
      } else if (+chainId == 80001 || +chainId == 137) {
        return {
          decimals: 18,
          name: 'MATIC',
          symbol: 'MATIC',
          balance: bnHelper.fromDecimals(bnbBalance)
        }
      } else if (+chainId == 43114 || +chainId == 43113) {
        return {
          decimals: 18,
          name: 'AVAX',
          symbol: 'AVAX',
          balance: bnHelper.fromDecimals(bnbBalance)
        }
      } else if (+chainId == 1 || +chainId == 3) {
        return {
          decimals: 18,
          name: 'ETH',
          symbol: 'ETH',
          balance: bnHelper.fromDecimals(bnbBalance)
        }
      }
    } catch (error) {
      console.log('====', error)
    }
  }

  get feeInfo() {
    return {
      txFee: this._txFee,
      isVip: this._isVIP
    }
  }

  async getWinnerList(id, length) {
    const methods = this.contract.methods
    const promises = [] as any
    const lengthParam = Math.ceil(MAX_LENGTH_PARAM)
    for (let i = 0; i < Math.ceil(length / MAX_LENGTH_PARAM); i++) {
      promises.push(methods.getWinnerList(id, i * lengthParam, lengthParam).call())
    }
    const res = await Promise.all(promises)
    return flattenDeep((res as any) || [])
  }

  async fetchClaimPoolsByOwner(owner: string) {
    const methods = this.contract.methods
    const claimIds = await methods.getClaimByOwners(owner).call()
    const clainIdsToNumber = claimIds?.map(id => Number(id))
    if (claimIds.length <= 0) return
    const res1: any[] = await blockchainHandler.etherBatchRequest(this.web3, [
      methods.getClaimInfoList(clainIdsToNumber),
      methods.getWinnerListLengthsByClaimIds(clainIdsToNumber)
    ])
    const claimInfos = res1[0]

    const winnerlistLengths = res1[1]

    const winnerlistPromises = [] as any
    winnerlistLengths?.map((item, index) => {
      winnerlistPromises.push(this.getWinnerList(claimIds[index], item))
    })
    const winnerLists = await Promise.all(winnerlistPromises)
    const tokenAddresses = map(claimInfos, 'tokenAddress')
    const promises = [] as any
    tokenAddresses?.map(item => promises.push(this.getTokenInfoByAddress(item, owner)))
    const tokenInfos = (await Promise.all(promises)) as any
    const claims = claimInfos?.map((item, index) => {
      const decimals = tokenInfos[index]?.decimals || 18
      return {
        ...item,
        createdDate: item.createdDate * 1000,
        amount: bnHelper.fromDecimals(item.amount, decimals),
        claimedAmount: bnHelper.fromDecimals(item.claimedAmount, decimals),
        winnerList: winnerLists[index],
        tokenInfo: tokenInfos[index]
      }
    })
    return claims
  }

  async getTokenInfoByAddress(tokenAddress, account = ADDRESS_0) {
    try {
      const web3 = this.web3
      const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), tokenAddress)
      const methods = tokenContract.methods
      const [name, decimals, symbol, balance] = await blockchainHandler.etherBatchRequest(web3, [
        methods.name(),
        methods.decimals(),
        methods.symbol(),
        methods.balanceOf(account)
      ])
      const balancefromWei = bnHelper.fromDecimals(balance, decimals)
      return {
        decimals,
        name,
        symbol,
        balance: balancefromWei,
        tokenAddress: tokenAddress
      }
    } catch (e) {
      //
    }
  }

  async fetchClaimPoolDetail(id) {
    const fetchClaim = await this.fetchClaimPoolDetailOwner(id, ADDRESS_0)
    const { winnerInfos, tokenInfo } = fetchClaim
    const decimals = tokenInfo?.decimals || 18
    const winnerAddressList = winnerInfos?.map(winner => winner.address)
    const winnerClaimedAmounts = await this.contract.methods.getWinnerClaimedAmount(id, winnerAddressList).call()
    const extendWinnerInfos = winnerInfos?.map((item, index) => {
      const totalClaimed = bnHelper.fromDecimals(winnerClaimedAmounts[index], decimals)
      return { ...item, totalClaimed, isClaimed: totalClaimed.toString() === item.amount.toString() }
    })
    this._cachedPool[id] = {
      ...fetchClaim,
      winnerInfos: extendWinnerInfos
    }
    return this._cachedPool[id]
  }

  async fetchAccountInfo(poolId, account) {
    let vestingScheduler: any
    let winnerList: any
    let decimals
    if (this._cachedPool[poolId]) {
      vestingScheduler = this._cachedPool[poolId].vestingScheduler
      winnerList = this._cachedPool[poolId].winnerList
      decimals = this._cachedPool[poolId]?.tokenInfo?.decimals || 18
    } else {
      const pool = await this.fetchClaimPoolDetail(poolId)
      winnerList = pool?.winnerList
      vestingScheduler = pool?.vestingScheduler
      decimals = pool?.decimals || 18
    }
    const [claimedAmount, ...userClaimeds] = await blockchainHandler.etherBatchRequest(this.web3, [
      this.contract.methods.winnerClaimedAmounts(poolId, account),
      ...map(new Array(toNumber(vestingScheduler.length)), (x, index) =>
        this.contract.methods.winnerClaimeds(poolId, index, account)
      )
    ])
    const extendVestingScheduler = vestingScheduler?.map((item, index) => {
      return { ...item, isClaimed: userClaimeds[index], isUnlockedDate: moment().isAfter(moment(item.date)) }
    })
    const acc = Web3.utils.toChecksumAddress(account)
    const isWinner = winnerList.includes(acc)
    return {
      claimedAmount: bnHelper.fromDecimals(claimedAmount, decimals),
      vestingScheduler: extendVestingScheduler,
      isWinner
    }
  }

  async fetchClaimPoolDetailOwner(id: number | string, account: string) {
    const methods = this.contract.methods
    const [
      claimInfo,
      vestingScheduler,
      getWinnerListLengthByClaimId
    ]: any = await blockchainHandler.etherBatchRequest(this.web3, [
      methods.CLAIMS(id),
      methods.getVestingScheduler(id),
      methods.getWinnerListLengthByClaimId(id)
    ])
    const { tokenAddress } = claimInfo
    const promises = [
      this.getTokenInfoByAddress(tokenAddress, account),
      this.getWinnerList(id, getWinnerListLengthByClaimId)
    ] as any

    const [tokenInfo, winnerList] = await Promise.all(promises)
    const decimals = (tokenInfo as any)?.decimals || 18
    const winnerInfos = await this.getWinnerInfoList(id, winnerList, decimals, account)
    const res = {
      ...claimInfo,
      createdDate: claimInfo.createdDate * 1000,
      amount: bnHelper.fromDecimals(claimInfo.amount, decimals),
      claimedAmount: bnHelper.fromDecimals(claimInfo.claimedAmount, decimals),
      winnerList,
      vestingScheduler: vestingScheduler?.map(item => ({
        ...item,
        date: item.date * 1000,
        percentage: bnHelper.fromDecimals(item.percentage)
      })),
      tokenInfo,
      winnerInfos
    }
    return res
  }

  async getWinnerInfoList(claimId, winnerList: any, decimals = 18, account) {
    const methods = this.contract.methods
    let res: any = []
    console.log('==========', winnerList)
    res = await methods.getWinnerMap(claimId, winnerList).call()
    return res?.map((item, index) => ({
      address: winnerList[index],
      amount: bnHelper.fromDecimals(item, decimals)
    }))
  }

  normalizeValues(values, decimals) {
    return values?.map(item => bnHelper.toDecimalString(item, decimals))
  }

  async getEstimateGas(res) {
    const estimateGasInWei = FixedNumber.from(`${res}`).mulUnsafe(
      FixedNumber.from(`${this.web3.utils.toWei('1', 'gwei')}`)
    )
    const feeInEth = bnHelper.fromDecimals(estimateGasInWei.toString())
    const ethPerBnbRatio = await priceHelper.ethPerBnbRatio()
    return feeInEth.mulUnsafe(FixedNumber.from(ethPerBnbRatio))
  }

  async estimateGasAddWinnerList(account: string, id, to, values, decimals = 18) {
    const valuesInWei = this.normalizeValues(values, decimals)
    const f = this.contract.methods.addWinnerList(id, to, valuesInWei)
    const res = await f.estimateGas({ from: account })
    return await this.getEstimateGas(res)
  }

  //get data from contract
  getVestingSchedulerLength(id: number): Promise<number> {
    return this.contract.methods.getVestingSchedulerLength(id).call()
  }
  getClaimByOwnersLength(account: string): Promise<number> {
    return this.contract.methods.getClaimByOwnersLength(account).call()
  }
  getClaimListByOwner(account: string, from: number, length: number): Promise<FixedNumber[]> {
    return this.contract.methods.getClaimListByOwner(account, from, length).call()
  }
  getWinnerListLengthByClaimId(id: number): Promise<number> {
    return this.contract.methods.getWinnerListLengthByClaimId(id).call()
  }
  getClaimProcessInfoById(id: number): Promise<[number, number, number]> {
    return this.contract.methods.getClaimProcessInfoById(id).call()
  }
  getWinnerMap(id: number, address: string[]): Promise<any> {
    return this.contract.methods.getWinnerMap(id, address).call()
  }
  //call function from contract
  addClaim(account: string, claimParam: ClaimParam, configs: VestingScheduler[], fee = '0'): Promise<any> {
    return sendRequest(this.contract.methods.addClaim(claimParam, configs), account, bnHelper.toDecimalString(fee))
  }

  addWinnerList(
    account: string,
    id: string | number,
    to: string[],
    values: any[],
    decimals: number,
    callback: any = null
  ): Promise<any> {
    const decimalValues = this.normalizeValues(values, decimals)
    return sendRequestWithCallBack(this.contract.methods.addWinnerList(id, to, decimalValues), account, callback)
  }
  claimTokens(account: string, id: string | number, vestingId: number): Promise<any> {
    return sendRequest(this.contract.methods.claimTokens(id, vestingId), account)
  }

  //not sendRequest
  isVIP(account: string): Promise<boolean> {
    return this.contract.methods.isVIP(account).call()
  }
  closeClaim(account: string, id: string | number): Promise<any> {
    return sendRequest(this.contract.methods.closeClaim(id), account)
  }
  pause(account: string, id: string | number): Promise<any> {
    return sendRequest(this.contract.methods.pause(id), account)
  }
  unpause(account: string, id: string | number): Promise<any> {
    return sendRequest(this.contract.methods.unpause(id), account)
  }
  changeDescription(account: string, id: string | number, description: string): Promise<any> {
    return sendRequest(this.contract.methods.changeDescription(id, description), account)
  }
  changeCoverUrl(account: string, id: string | number, coverUrl: string): Promise<any> {
    return sendRequest(this.contract.methods.changeCoverUrl(id, coverUrl), account)
  }
  changeAvatarUrl(account: string, id: string | number, avatarUrl: string): Promise<any> {
    return sendRequest(this.contract.methods.changeAvatarUrl(id, avatarUrl), account)
  }
  changeName(account: string, id: string | number, name: string): Promise<any> {
    return sendRequest(this.contract.methods.changeName(id, name), account)
  }

  // async cost(amount: FixedNumber): Promise<FixedNumber> {
  //   const amountInDecimals = bnHelper.toDecimalString(amount, this.erc20Decimals)
  //   return bnHelper.fromDecimals(await this.contract.methods.cost(amountInDecimals).call(), this.tradeErc20Decimals)
  // }
  //not implement yet
  addToVIPList(account: string, vipList: string[]): Promise<any> {
    throw new Error('Method not implemented.')
  }
  removeFromVIPList(account: string, vipList: string[]): Promise<any> {
    throw new Error('Method not implemented.')
  }
  setReceiverAddress(account: string, address: string): Promise<any> {
    throw new Error('Method not implemented.')
  }
}
