import { bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import Web3 from 'web3'
import { blockchainHandler } from '.'
import { IMultiSendContract, ITokenInfo } from './multisend-contract-interface'
import priceHelper from '@/helpers/priceHelper.js'
import { FixedNumber } from '@ethersproject/bignumber'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

export const DEFAULT_TOKEN_SYMBOL = 'BNB'

async function sendRequest(fx, from, value = '0') {
  return await new Promise((resolve, reject) => {
    fx.send({ from, value })
      .on('receipt', () => resolve(true))
      .on('error', error => reject(error))
  })
}

async function sendRequestWithCallBack(fx, from, callback = null as any, value = '0') {
  return await new Promise((resolve, reject) => {
    fx.send({ from, value })
      .on('transactionHash', hash => {
        if (callback) resolve(hash)
      })
      .on('receipt', () => {
        if (callback) {
          callback({ completed: true })
        }
        resolve(true)
      })
      .on('error', error => {
        if (callback) {
          callback({ completed: false })
        }
        reject(error)
      })
  })
}

export const sendTypes = {
  MULTI_SEND_ETH_WITH_SAME_VALUE: 1,
  MULTI_SEND_ETH_WITH_DIFFERENT_VALUE: 2,
  MULTI_SEND_COIN_WITH_SAME_VALUE: 3,
  MULTI_SEND_COIN_WITH_DIFFERENT_VALUE: 4
}

export class SolidityMultiSendContract implements IMultiSendContract {
  contract: any
  erc20Decimals = 18
  _loadTask?: Promise<any>
  _loaded = false
  bnbInfo: any
  _txFee = FixedNumber.from('0')
  _VIPFee = FixedNumber.from('0')
  defaultTokenSymbol = ''

  constructor(public address: string, public web3: Web3) {
    this.contract = new web3.eth.Contract(require('../abis/multisend-contract.json'), this.address)
  }

  injectProvider() {
    const web3 = walletStore.web3!
    this.web3 = web3
    console.log('this.web3', this.web3)
    this.contract = new web3.eth.Contract(require('../abis/multisend-contract.json'), this.address)
  }

  init(): Promise<any> {
    if (this._loaded) {
      return Promise.resolve()
    }
    if (this._loadTask) {
      return this._loadTask
    }

    // eslint-disable-next-line no-async-promise-executor
    this._loadTask = new Promise(async (resolve, reject) => {
      try {
        const methods = this.contract.methods
        const [txFee, vipFee] = (await blockchainHandler.etherBatchRequest(this.web3, [
          methods.txFee(),
          methods.VIPFee()
        ])) as any

        this._txFee = bnHelper.fromDecimals(txFee)
        this._VIPFee = bnHelper.fromDecimals(vipFee)
        this._loaded = true
        resolve(null)
      } catch (error) {
        reject(error)
      }
    })
    return this._loadTask
  }

  async getBnbInfo(account: string) {
    try {
      const chainId = walletStore.chainId
      const bnbBalance = await this.web3.eth.getBalance(account)
      if (+chainId === 56 || +chainId == 97) {
        this.defaultTokenSymbol = 'BNB'
        return {
          decimals: 18,
          name: 'BNB',
          symbol: 'BNB',
          balance: bnHelper.fromDecimals(bnbBalance)
        }
      } else if (+chainId == 80001 || +chainId == 137) {
        this.defaultTokenSymbol = 'MATIC'
        return {
          decimals: 18,
          name: 'MATIC',
          symbol: 'MATIC',
          balance: bnHelper.fromDecimals(bnbBalance)
        }
      } else if (+chainId == 43114 || +chainId == 43113) {
        this.defaultTokenSymbol = 'AVAX'
        return {
          decimals: 18,
          name: 'AVAX',
          symbol: 'AVAX',
          balance: bnHelper.fromDecimals(bnbBalance)
        }
      } else if (+chainId == 1 || +chainId == 3) {
        this.defaultTokenSymbol = 'ETH'
        return {
          decimals: 18,
          name: 'ETH',
          symbol: 'ETH',
          balance: bnHelper.fromDecimals(bnbBalance)
        }
      }
    } catch (error) {
      console.log('====', error)
    }
  }

  async getTokenInfoByAddress(tokenAddress, account) {
    const web3 = this.web3
    const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), tokenAddress)
    const methods = tokenContract.methods
    const [name, decimals, symbol, balance] = await blockchainHandler.etherBatchRequest(web3, [
      methods.name(),
      methods.decimals(),
      methods.symbol(),
      methods.balanceOf(account)
    ])
    const balancefromWei = bnHelper.fromDecimals(balance, decimals)
    return {
      decimals,
      name,
      symbol,
      balance: balancefromWei,
      tokenAddress: tokenAddress
    }
  }

  async approveContract(account: string, token?: any) {
    const tokenConract = new this.web3.eth.Contract(require('@/helpers/erc20.abi.json'), token.tokenAddress)
    const f = tokenConract.methods.approve(this.contract._address, this.web3.utils.toWei(`${2 ** 64 - 1}`))
    try {
      await sendRequest(f, account)
      return 'confirmed'
    } catch (e) {
      // alert.error('approve failed')
      // alert(e.message)
    }
  }

  async approvedContract(account: string, token) {
    try {
      const tokenContract = new this.web3.eth.Contract(require('@/helpers/erc20.abi.json'), token.tokenAddress)
      const allowance = await tokenContract.methods.allowance(account, this.contract._address).call()
      return !!+this.web3.utils.fromWei(allowance)
    } catch (e) {
      console.error(e)
      // alert.error(e.message)
      return false
    }
  }

  normalizeValues(values, decimals) {
    return values.map(item => bnHelper.toDecimalString(item, decimals))
  }

  async getEstimateGas(res) {
    const estimateGasInWei = FixedNumber.from(`${res}`).mulUnsafe(
      FixedNumber.from(`${this.web3.utils.toWei('1', 'gwei')}`)
    )
    const feeInEth = bnHelper.fromDecimals(estimateGasInWei.toString())
    const ethPerBnbRatio = await priceHelper.ethPerBnbRatio()
    return feeInEth.mulUnsafe(FixedNumber.from(ethPerBnbRatio))
  }

  async estimateGasBulkSend(account: string, to, values, token: ITokenInfo) {
    const sendType = values.filter((v, i, a) => a.indexOf(v) === i).length === 1 ? 'sameValue' : 'differentValue'
    const valuesInWei = this.normalizeValues(values, token.decimals)
    let f
    if (sendType === 'sameValue') {
      if (token?.symbol === this.defaultTokenSymbol)
        f = this.contract.methods.mutiSendETHWithSameValue(to, valuesInWei[0])
      else if (token.tokenAddress) f = this.contract.methods.mutiSendETHWithSameValue(to, valuesInWei)
    } else {
      if (token?.symbol === this.defaultTokenSymbol)
        f = this.contract.methods.mutiSendCoinWithSameValue(to, valuesInWei[0])
      else if (token.tokenAddress) f = this.contract.methods.mutiSendCoinWithSameValue(to, valuesInWei)
    }
    const res = await f.estimateGas({ from: account })
    return await this.getEstimateGas(res)
  }

  bulkSend(
    to: string[],
    values: FixedNumber[],
    fee = FixedNumber.from('0'),
    account: string,
    callback: any = null,
    token: ITokenInfo
  ): any {
    try {
      const sendType = values.filter((v, i, a) => a.indexOf(v) === i).length === 1 ? 'sameValue' : 'differentValue'
      if (sendType === 'sameValue') {
        if (token?.symbol === this.defaultTokenSymbol) {
          const sendAmount = values[0].mulUnsafe(FixedNumber.from(to.length))
          const totalETHSend = sendAmount.addUnsafe(FixedNumber.from(fee))
          return sendRequestWithCallBack(
            this.mutiSendETHWithSameValue(to, bnHelper.toDecimalString(values[0])),
            account,
            callback,
            bnHelper.toDecimalString(totalETHSend)
          )
        } else if (token.tokenAddress) {
          return sendRequestWithCallBack(
            this.mutiSendCoinWithSameValue(token.tokenAddress, to, bnHelper.toDecimalString(values[0])),
            account,
            callback,
            bnHelper.toDecimalString(fee, token.decimals)
          )
        }
        return
      } else {
        if (token?.symbol === this.defaultTokenSymbol) {
          const sendAmount = values.reduce((a, b) => {
            return a.addUnsafe(b)
          }, FixedNumber.from('0'))
          const totalETHSend = sendAmount.addUnsafe(FixedNumber.from(fee))
          return sendRequestWithCallBack(
            this.mutiSendETHWithDifferentValue(to, this.normalizeValues(values, token.decimals)),
            account,
            callback,
            bnHelper.toDecimalString(totalETHSend)
          )
        } else if (token.tokenAddress) {
          return sendRequestWithCallBack(
            this.mutiSendCoinWithDifferentValue(token.tokenAddress, to, this.normalizeValues(values, token.decimals)),
            account,
            callback,
            bnHelper.toDecimalString(fee, token.decimals)
          )
        }
        return
      }
    } catch (error) {
      snackController.error(error.message)
    }
  }

  //Call function of contract
  registerVIP(): Promise<void> {
    return this.contract.methods.registerVIP().call()
  }
  getReceiverAddress(): Promise<string> {
    return this.contract.methods.getReceiverAddress().call()
  }
  isVIP(address: string): Promise<boolean> {
    return this.contract.methods.isVIP(address).call()
  }
  sendEth(to: string[], value: number): Promise<void> {
    return this.contract.methods.sendEth(to, value).call()
  }
  multisend(to: string[], value: number): Promise<void> {
    return this.contract.methods.multisend(to, value).call()
  }
  mutiSendETHWithDifferentValue(to: string[], value: any): Promise<void> {
    return this.contract.methods.mutiSendETHWithDifferentValue(to, value)
  }
  mutiSendETHWithSameValue(to: string[], value: any): Promise<void> {
    return this.contract.methods.mutiSendETHWithSameValue(to, value)
  }
  mutiSendCoinWithSameValue(tokenAddress: string, to: string[], value: any): Promise<void> {
    return this.contract.methods.mutiSendCoinWithSameValue(tokenAddress, to, value)
  }
  mutiSendCoinWithDifferentValue(tokenAddress: string, to: string[], value: any): Promise<void> {
    return this.contract.methods.mutiSendCoinWithDifferentValue(tokenAddress, to, value)
  }
  multisendToken(tokenAddress: string, to: string[], value: number): Promise<void> {
    return this.contract.methods.multisendToken(tokenAddress, to, value).call()
  }
  drop(tokenAddress: string, to: string[], value: number): Promise<void> {
    return this.contract.methods.drop(tokenAddress, to, value).call()
  }
  removeEth(to: string): Promise<void> {
    return this.contract.methods.removeEth(to).call()
  }
  removeErc20(tokenAddress: string, to: string): Promise<void> {
    return this.contract.methods.removeErc20(to).call()
  }
  addToVIPList(vipList: string[]): Promise<void> {
    return this.contract.methods.addToVIPList(vipList).call()
  }
  removeFromVIPList(vipList: string[]): Promise<void> {
    return this.contract.methods.removeFromVIPList(vipList).call()
  }
  setReceiverAddress(address: string): Promise<void> {
    return this.contract.methods.setReceiverAddress(address).call()
  }
}
